<template>
  <div>
    <v-layout pb-10 wrap justify-center>
      <v-snackbar v-model="showSnackBar" color="black">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: white">
              {{ msg }}
            </span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <vue-element-loading
        :active="appLoading"
        :is-full-screen="true"
        background-color="#FFFFFF"
        color="#13736f"
        spinner="spinner"
      />
      <v-flex xs12>
        <v-layout wrap justify-center>
          <v-flex xs11 pt-6>
            <v-card style="border-radius: 0px">
              <v-layout pb-6 wrap justify-center>
                <v-flex xs11 pl-5 pb-2 pt-5 text-left>
                  <span class="mainfont" style="color: black; font-size: 16px">
                    <strong>Lot Details</strong>
                  </span>
                </v-flex>

                <v-flex xs12>
                  <v-layout wrap justify-center>
                    <v-flex xs4 pl-16>
                      <v-layout wrap class="mainfont">
                        <v-flex xs6 pt-3 style="color: black; font-size: 14px"
                          >LOT NO</v-flex
                        >
                        <v-flex
                          xs6
                          pt-3
                          style="color: #625e5e; font-size: 14px"
                          >{{ list.lotNumber }}</v-flex
                        >

                        <v-flex xs6 pt-3 style="color: black; font-size: 14px"
                          >CATEGORY</v-flex
                        >
                        <v-flex
                          xs6
                          pt-3
                          style="color: #625e5e; font-size: 14px"
                        >
                          <span v-if="list">
                            <span v-if="list.categoryId">
                              <span v-if="list.categoryId.name">
                                {{ list.categoryId.name }}
                              </span>
                            </span>
                          </span>
                        </v-flex>

                        <v-flex xs6 pt-3 style="color: black; font-size: 14px"
                          >BAG COUNT
                        </v-flex>
                        <v-flex
                          xs6
                          pt-3
                          style="color: #625e5e; font-size: 14px"
                          >{{ list.bagCount }}</v-flex
                        >

                        <v-flex xs6 pt-3 style="color: black; font-size: 14px"
                          >BID PRICE
                        </v-flex>
                        <v-flex xs6 pt-3 style="color: #625e5e; font-size: 14px"
                          >Rs&nbsp;{{ list.bidPrice }}
                        </v-flex>
                        <v-flex
                          v-if="list.literWeight"
                          xs6
                          pt-3
                          style="color: black; font-size: 14px"
                          >LITER WEIGHT</v-flex
                        >
                        <v-flex
                          v-if="list.literWeight"
                          xs6
                          pt-3
                          style="color: #625e5e; font-size: 14px"
                          >{{ list.literWeight }}&nbsp;Gm</v-flex
                        >

                        <v-flex xs6 pt-3 style="color: black; font-size: 14px"
                          >DATE</v-flex
                        >
                        <v-flex
                          xs6
                          pt-3
                          style="color: #625e5e; font-size: 14px"
                        >
                          {{ formatDate(list.date) }}</v-flex
                        >
                        <v-flex
                          v-if="list.white"
                          xs6
                          pt-3
                          style="color: black; font-size: 14px"
                        >
                          WHITE PEPPER</v-flex
                        >

                        <v-flex
                          v-if="list.white"
                          xs6
                          pt-3
                          style="color: #625e5e; font-size: 14px"
                        >
                          {{ list.white }}&nbsp;%
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex pl-16 xs4>
                      <v-layout wrap class="mainfont">
                        <v-flex xs6 pt-3 style="color: black; font-size: 14px"
                          >TYPE</v-flex
                        >
                        <v-flex
                          xs6
                          pt-3
                          style="color: #625e5e; font-size: 14px"
                          >{{ list.lotType }}</v-flex
                        >

                        <v-flex
                          v-if="list.dust"
                          xs6
                          pt-3
                          style="color: black; font-size: 14px"
                          >DUST</v-flex
                        >
                        <v-flex
                          v-if="list.dust"
                          xs6
                          pt-3
                          style="color: #625e5e; font-size: 14px"
                          >{{ list.dust }}</v-flex
                        >

                        <v-flex
                          v-if="list.majorityColor"
                          xs6
                          pt-3
                          style="color: black; font-size: 14px"
                          >MAJORITY COLOR</v-flex
                        >
                        <v-flex
                          v-if="list.majorityColor"
                          xs6
                          pt-3
                          style="color: #625e5e; font-size: 14px"
                          >{{ list.majorityColor }}</v-flex
                        >

                        <v-flex xs6 pt-3 style="color: black; font-size: 14px">
                          MOISTURE CONTENT</v-flex
                        >

                        <v-flex
                          xs6
                          pt-3
                          style="color: #625e5e; font-size: 14px"
                        >
                          {{ list.moistureContent }}
                        </v-flex>

                        <v-flex
                          v-if="list.isFungus"
                          xs6
                          pt-3
                          style="color: black; font-size: 14px"
                        >
                          IS FUNGUS</v-flex
                        >

                        <v-flex
                          v-if="list.isFungus === true"
                          xs6
                          pt-3
                          style="
                            color: #13736f;
                            font-size: 14px;
                            font-weight: bold;
                          "
                        >
                          YES
                        </v-flex>

                        <v-flex
                          v-else-if="list.isFungus === false"
                          xs6
                          pt-3
                          style="color: red; font-size: 14px"
                        >
                          NO
                        </v-flex>

                        <v-flex
                          v-if="list.size"
                          xs6
                          pt-3
                          style="color: black; font-size: 14px"
                        >
                          SIZE</v-flex
                        >

                        <v-flex
                          v-if="list.size"
                          xs6
                          pt-3
                          style="color: #625e5e; font-size: 14px"
                        >
                          {{ list.size }}
                        </v-flex>

                        <v-flex
                          v-if="list.startingTime"
                          xs6
                          pt-3
                          style="color: black; font-size: 14px"
                        >
                          STARTING TIME</v-flex
                        >

                        <v-flex
                          v-if="list.startingTime"
                          xs6
                          pt-3
                          style="color: #625e5e; font-size: 14px"
                        >
                          {{ formatTime2(list.startingTime) }}
                        </v-flex>

                        <v-flex
                          v-if="list.buds"
                          xs6
                          pt-3
                          style="color: black; font-size: 14px"
                        >
                          BUDS</v-flex
                        >
                        <v-flex
                          v-if="list.buds"
                          xs6
                          pt-3
                          style="color: #625e5e; font-size: 14px"
                        >
                          {{ list.buds }}
                        </v-flex>

                        <v-flex
                          v-if="list.sickSplitTotal"
                          xs6
                          pt-3
                          style="color: black; font-size: 14px"
                          >SICK SPLIT TOTAL</v-flex
                        >
                        <v-flex
                          v-if="list.sickSplitTotal"
                          xs6
                          pt-3
                          style="color: #625e5e; font-size: 14px"
                          >{{ list.sickSplitTotal }}</v-flex
                        >
                      </v-layout>
                    </v-flex>
                    <v-flex pl-16 xs4>
                      <v-layout wrap class="mainfont">
                        <v-flex xs6 pt-3 style="color: black; font-size: 14px"
                          >STARTING PRICE
                        </v-flex>
                        <v-flex xs6 pt-3 style="color: #625e5e; font-size: 14px"
                          >Rs&nbsp;{{ list.startingPrice }}</v-flex
                        >

                        <v-flex xs6 pt-3 style="color: black; font-size: 14px"
                          >EXPECTED PRICE
                        </v-flex>
                        <v-flex xs6 pt-3 style="color: #625e5e; font-size: 14px"
                          >Rs&nbsp;{{ list.expectedPrice }}</v-flex
                        >

                        <v-flex xs6 pt-3 style="color: black; font-size: 14px"
                          >NET WEIGHT
                        </v-flex>
                        <v-flex xs6 pt-3 style="color: #625e5e; font-size: 14px"
                          >{{ list.netWeight }}&nbsp;Kg</v-flex
                        >

                        <v-flex xs6 pt-3 style="color: black; font-size: 14px"
                          >HARVEST</v-flex
                        >
                        <v-flex
                          xs6
                          pt-3
                          style="color: #625e5e; font-size: 14px"
                          >{{ list.harvest }}</v-flex
                        >

                        <v-flex xs6 pt-3 style="color: black; font-size: 14px"
                          >BAG PRICE</v-flex
                        >
                        <v-flex xs6 pt-3 style="color: #625e5e; font-size: 14px"
                          >Rs&nbsp;{{ list.bagPrice }}</v-flex
                        >

                        <v-flex
                          v-if="list.endingTime"
                          xs6
                          pt-3
                          style="color: black; font-size: 14px"
                        >
                          ENDING TIME</v-flex
                        >

                        <v-flex
                          v-if="list.endingTime"
                          xs6
                          pt-3
                          style="color: #625e5e; font-size: 14px"
                        >
                          {{ formatTime2(list.endingTime) }}
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex
                      xs3
                      pt-3
                      class="mainfont"
                      text-right
                      style="color: black; font-size: 14px"
                    >
                      <v-card color="#13736f" elevation="0" class="pa-2">
                        <v-layout wrap>
                          <v-flex xs12 text-center>
                            <span
                              class="mainfont"
                              style="color: white; font-size: 14px"
                            >
                              Ends in &nbsp;</span
                            >

                            <span
                              class="mainfont"
                              style="color: white; font-size: 16px"
                              >{{ formatTimeDifference(timediff) }}</span
                            >
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-divider></v-divider>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center>
          <v-flex xs11>
            <v-card elevation="0" style="border-radius: 0px">
              <v-layout wrap justify-start>
                <v-flex pl-9 pb-6 pt-9 lg4 v-for="(item, i) in photos" :key="i">
                  <v-img contain :src="mediaURL + item"> </v-img>
                </v-flex>

                <v-flex xs7>
                  <v-layout v-if="grades.length > 0" wrap justify-center>
                    <v-flex xs11>
                      <v-card class="pa-6 sharpEdges" elevation="0">
                        <v-layout wrap>
                          <v-flex xs12>
                            <v-simple-table class="mainfont">
                              <template v-slot:default>
                                <thead>
                                  <tr>
                                    <th
                                      class="mainfont"
                                      style="
                                        background-color: #13736f;
                                        color: white;
                                        font-size: 15px;
                                        font-weight: lighter;
                                      "
                                    >
                                      Grade
                                    </th>
                                    <th
                                      class="mainfont"
                                      style="
                                        background-color: #13736f;
                                        color: white;
                                        font-size: 15px;
                                        font-weight: lighter;
                                      "
                                    >
                                      Clean
                                    </th>

                                    <th
                                      class="mainfont"
                                      style="
                                        background-color: #13736f;
                                        color: white;
                                        font-size: 15px;
                                        font-weight: lighter;
                                      "
                                    >
                                      Fruit Whitish&nbsp;%
                                    </th>
                                    <th
                                      class="mainfont"
                                      style="
                                        background-color: #13736f;
                                        color: white;
                                        font-size: 15px;
                                        font-weight: lighter;
                                      "
                                    >
                                      Sick Split(%)
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(item, i) in grades"
                                    :key="i"
                                    style="cursor: pointer"
                                  >
                                    <td v-if="item" class="mainbg4">
                                      <span>
                                        {{ item.grade }}
                                      </span>
                                    </td>
                                    <td v-if="item" class="mainbg4">
                                      <span>
                                        {{ item.clean }}
                                      </span>
                                    </td>
                                    <td v-if="item" class="mainbg4">
                                      <span>
                                        {{ item.fruitWhitish }}
                                      </span>
                                    </td>
                                    <td v-if="item" class="mainbg4">
                                      <span>
                                        {{ item.sickSplit }}
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
    
    
<script>
import axios from "axios";
import moment from "moment";
export default {
  data() {
    return {
      currentTime: null,
      timeDifferenceInMinutes: null,
      preview: null,
      endingTime: null,
      link: null,
      page: 1,
      grades: [],
      currentpage: 1,
      showSnackBar: false,
      msg: null,
      limit: 10,
      url: null,
      categoryName: null,
      id: this.$route.query.id,
      appLoading: false,
      name: null,
      description: null,
      duration: null,
      pages: 0,
      icon: null,
      formData: new FormData(),
      photos: [],
      listingOrder: null,
      date: new Date(),
      list: [],
      curid: [],
      deletedialog: false,
      editdialog: false,
      noOfDays: null,
      timediff: null,
      amount: null,
      dialog2: false,
    };
  },
  mounted() {
    this.getList();
  },
  beforeDestroy() {
    clearInterval(this.timerInterval);
  },

  created() {
    this.currentTime = new Date();
    this.calculateTimeDifference();

    this.timerInterval = setInterval(() => {
      if (this.timediff > 0) {
        this.timediff -= 1;
      }
    }, 1000);
  },

  methods: {
    formatDate(item) {
      var dt = new Date(item);
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },

    formatTime12Hour(time) {
      if (time instanceof Date) {
        const options = {
          hour: "numeric",
          minute: "2-digit",
          second: "2-digit",
          hour12: true,
        };
        return time.toLocaleTimeString(undefined, options);
      } else {
        return "Invalid Date";
      }
    },
    formatTime2(time) {
      return moment(time).format("HH:mm");
    },
    formatTime(time) {
      if (time instanceof Date) {
        const options = {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: false,
        };
        return time.toLocaleTimeString(undefined, options);
      } else {
        return "Invalid Date";
      }
    },

    calculateTimeDifference() {
      if (this.currentTime instanceof Date && this.endingTime instanceof Date) {
        const timeDifference =
          this.endingTime.getTime() - this.currentTime.getTime();

        this.timediff = Math.floor(timeDifference / 1000);
      }
    },

    formatTwoDigits(number) {
      return String(number).padStart(2, "0");
    },

    formatTimeDifference(seconds) {
      if (typeof seconds === "number" && seconds >= 0) {
        const hours = Math.floor(seconds / 3600);
        const remainingSeconds = seconds % 3600;
        const minutes = Math.floor(remainingSeconds / 60);
        const remainingSecondsFinal = remainingSeconds % 60;

        return `${this.formatTwoDigits(hours)}:${this.formatTwoDigits(
          minutes
        )}:${this.formatTwoDigits(remainingSecondsFinal)}`;
      } else {
        return "Invalid Time Difference";
      }
    },

    getList() {
      this.appLoading = true;

      axios({
        method: "GET",
        url: "/lot/view/" + this.id,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.list = response.data.data;
          this.photos = this.list.photos;
          this.grades = this.list.grades;
          this.endingTime = new Date(this.list.endingTime);
          this.calculateTimeDifference();
          this.appLoading = false;
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
    
    
    <style scoped>
.flex-red {
  background-color: #13736f;
}

.flex-g {
  background-color: #13736f;
}
.bg8 {
  background: var(
    --grend,
    linear-gradient(
      252deg,
      #e4ecee 0%,
      #9bc2c2 0%,
      #6ca6a5 28%,
      #157470 88%,
      #13736f 100%
    )
  );
}
</style>